/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function e(e, t, n, r) {
  var o,
    c = arguments.length,
    f = c < 3 ? t : null === r ? r = Object.getOwnPropertyDescriptor(t, n) : r;
  if ("object" == typeof Reflect && "function" == typeof Reflect.decorate) f = Reflect.decorate(e, t, n, r);else for (var u = e.length - 1; u >= 0; u--) (o = e[u]) && (f = (c < 3 ? o(f) : c > 3 ? o(t, n, f) : o(t, n)) || f);
  return c > 3 && f && Object.defineProperty(t, n, f), f;
}
function t(e, t) {
  return function (n, r) {
    t(n, r, e);
  };
}
function n(e, t, n, r) {
  function o(e) {
    return e instanceof n ? e : new n(function (t) {
      t(e);
    });
  }
  return new (n || (n = Promise))(function (n, c) {
    function f(e) {
      try {
        i(r.next(e));
      } catch (t) {
        c(t);
      }
    }
    function u(e) {
      try {
        i(r.throw(e));
      } catch (t) {
        c(t);
      }
    }
    function i(e) {
      e.done ? n(e.value) : o(e.value).then(f, u);
    }
    i((r = r.apply(e, t || [])).next());
  });
}
"function" == typeof SuppressedError && SuppressedError;
export { e as _, t as a, n as b };